import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const AboutPage = () => (
  <Layout>
    <nav class="navbar">
      <Link class="navbar-brand" to="/">Volver</Link>
    </nav>
    <SEO title="Inicio" />
    <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-28.jpg" alt="Arbol" />
    <div className="row  justify-content-center">
      <div className="col-10 col-xs-12">
        <h1 className="page__subtitle mt-5" >Acto primero</h1>
        <h2 className="page__title mb-3">Un metro por un metro</h2>
        <p class="page__author mb-5">Ensayo de Fernanda Villegas</p>
        <p>
          En la comunidad maya de Xocén al oriente de Yucatán se busca un <em>Libro sagrado</em> o <em>Libro de las Profecías</em> que se presume se perdió entre 1880 y 1945. Se trata de un libro sagrado para los mayas, el cual antes de desaparecer se encontraba en esta comunidad,
          se dice que “En ese libro está escrito todo, cómo es el mundo, cómo se hacen las cosas y cómo se va a acabar. Aquél libro evidentemente no es cualquier libro, sus medidas son de un metro por un metro y se dice que está vivo; se abre
          solo y una hoja únicamente da vuelta cada día, y si uno quiere abrirlo más adelante, sangra…”. (Terán, 2013)
          <br /><br /> Cuentan que “se encontró cierto día ese famoso libro al pie de la Santa Cruz Tun y desde siempre se ha guardado en la memoria de chicos y grandes las predicciones que en interior suyo estaba escrito. La última vez que lo vieron
          fue en la villa de Zací.” <span className="no-wrap">(Dzib y Noh, 1999).</span>
          <br /><br /> Una variante del relato dice que un día un cazador que andaba por el bosque vio una cruz de piedra y que los animales le estaban cantando, porque en ese tiempo sólo ellos lo adoraban.
          <br /><br /> Está dicho, y así lo repite el Libro, cuándo el mundo se va a acabar, y no sabemos si ya empezó a acabarse por todo lo que vemos y oímos. Porque la aguada de la mano derecha de la cruz hace tiempo que ya empezó a secarse, y
          ya no vienen los animales del monte a tomar agua; ya no viene la serpiente ni el venado ni el faisán, ya no vemos como antes tantos animales por aquí; además ya no sabemos si la noche es noche o si ya está muy cerca esa noche que nunca
          acabará.
        </p>
      </div>
    </div>
  </Layout>
)

export default AboutPage

// En el Libro se dice que, "un día un chapeador, chapeando, chapeando, de repente junto a un gran cenote y una mata de chacá se encontró esa cruz que tenía una aguada en su brazo derecho; en verdad eran tres cruces y no sólo
//         una, bien es cierto que aquí está el Centro del Mundo y le llamaremos a esta cruz Santísima Cruz Balam Tun, Ki´ichkelem Yúum Oxlahum ti ku".